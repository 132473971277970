import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { SeoProps } from './models';

const Seo: FC<SeoProps> = ({
  siteSettings,
  brandSettings,
  seoMetaKeywords,
  seoMetaTitle,
  seoMetaTitleTemplate,
  seoMetaDescription,
  seoExternalHreflangs,
  seoNoIndex,
  seoCanonicalUrl,
  imageUrl,
  imageAlt,
  alternateUrls,
  pageUrl,
}) => {
  const siteTitle = siteSettings?.siteName || '';
  const siteAuthor = brandSettings?.brandName;
  const siteLang = siteSettings?.siteLang || siteSettings?.lang;
  const siteDir = siteSettings?.isRTL ? 'rtl' : 'ltr';
  const ogImageUrl = imageUrl || brandSettings?.defaultOgImageUrl;
  const hideOneTrustUI = siteSettings?.oneTrustDisabledOnPages?.some(({ url }) => url === pageUrl);

  const titleTemplate =
    seoMetaTitleTemplate || (seoMetaTitle === siteTitle ? '%s' : `%s | ${siteTitle}`);

  const externalAlternateLinks = seoExternalHreflangs?.length
    ? seoExternalHreflangs?.map((item) => ({
      rel: 'alternate',
      href: item.value,
      hreflang: item.key,
    }))
    : [];

  useEffect(() => {
    window?.OneTrust?.changeLanguage(siteLang.split('-')[0]);
  }, [siteLang]);

  const alternateUrlsArr =
    alternateUrls?.map((el) => ({
      rel: 'alternate',
      href: el.url,
      hreflang: el.lang,
    })) || [];

  const link = [...externalAlternateLinks, ...alternateUrlsArr];

  const indexVariant = seoNoIndex ? 'noindex, follow' : 'index, follow';

  const meta = [
    {
      name: 'robots',
      content: process.env.GATSBY_ACTIVE_ENV === 'production' ? indexVariant : 'noindex, nofollow',
    },
    {
      name: 'description',
      content: seoMetaDescription,
    },
    {
      property: 'og:url',
      content: `${siteSettings.siteUrl?.replace(/\/$/, '')}${pageUrl}`,
    },
    {
      property: 'og:title',
      content: seoMetaTitle || 'Finish',
    },
    ...(ogImageUrl
      ? [
        {
          property: 'og:image',
          content: ogImageUrl,
        },
      ]
      : []),
    {
      property: 'og:description',
      content: seoMetaDescription || 'Finish',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteAuthor,
    },
    {
      name: 'twitter:title',
      content: seoMetaTitle,
    },
    {
      name: 'twitter:description',
      content: seoMetaDescription,
    },
    {
      name: 'twitter:image:src',
      content: imageUrl || '',
    },
    {
      name: 'twitter:image:alt',
      content: imageAlt || '',
    },
    ...(seoMetaKeywords?.length
      ? [
        {
          name: 'keywords',
          content: seoMetaKeywords.join(', '),
        },
      ]
      : []),
    ...(process.env.GATSBY_YANDEX_WEBMASTER_KEY
      ? [
        {
          name: 'yandex-verification',
          content: process.env.GATSBY_YANDEX_WEBMASTER_KEY,
        },
      ]
      : []),
  ];

  const script = siteSettings.commonScriptTags?.map(({ properties: { id, src } }) => ({
    src,
    ...(id && { id }),
    async: true,
  }));

  return (
    <Helmet
      htmlAttributes={{ lang: siteLang, dir: siteDir }}
      {...{ title: seoMetaTitle || siteTitle || 'Finish', titleTemplate, meta, link, script }}
      {...(hideOneTrustUI && { bodyAttributes: { class: 'onetrust-ui-hidden' } })}
    >
      {seoCanonicalUrl?.url ? (
        <link
          rel="canonical"
          href={`${siteSettings.siteUrl?.replace(/\/$/, '')}${seoCanonicalUrl.url}`}
        />
      ) : null}
    </Helmet>
  );
};

export const query = graphql`
  fragment FragmentSiteSettingsComposition on TSiteSettingsComposition {
    salsifyLang
    siteLang
    isRTL
    useSalsify
    siteUrl
    lang
    siteName
    isShopify
    usePriceSpider
    psKey
    psCountry
    psLanguage
    psScript
    bvScript
    isEanProductId
    commonScriptTags {
      properties {
        id
        src
      }
    }
    disableProductPageSchema
    oneTrustDisabledOnPages {
      url
    }
  }

  fragment FragmentBrandSettingsComposition on TBrandSettingsComposition {
    brandName
    logo {
      ...FragmentGatsbyImage
    }
    logoAlt
    defaultOgImageUrl
  }
`;

export default Seo;
